import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ComingSoonModal from './ComingSoonModal';
import Auth from '../pages/Auth';
import silverLogo from '../assets/images/silverlogo.png';
import footerWave from '../assets/images/footerwave.png';


const Footer = () => {
    const token = useSelector(state => state?.AuthSlice?.auth_data?.data?.token);
    const [comingSoonModal, setComingSoonModal] = useState(false);
    const [authModal, setAuthModal] = useState(false);

    return (
        <>
            <footer className='mt-5'>
                {/* <div className='footer_wave_margin'>
                    <img src={footerWave} alt="footerWave" width='100%' />
                </div> */}
                <div className="footer_div">
                    <Link to='/'><img src={silverLogo} alt="logo" className='' width='11%' /></Link>
                    <div className="footer_anchor_list mt-4 mt-md-5 mt-lg-5">
                        <Link to="/">Home</Link>
                        <Link to="/venues">Venues</Link>
                        <Link to="https://www.instagram.com/glenwoodsouthdistrict/reels/" target='_blank'>Feed</Link>
                        {token ? <Link to="/sky-cams">Sky Cams</Link> : <Link onClick={() => setAuthModal(true)}>Sky Cams</Link>}
                        <Link to="/map">Map</Link>
                        {/* <Link to="/product-list">Shop</Link> */}
                        <Link to="#" onClick={() => setComingSoonModal(true)}>Shop</Link>
                        <Link to="/download">Download App</Link>
                        <Link to="/contact">Contact Us</Link>
                    </div>
                    <div className="last_footer">
                        <span className='small_font text-white'>&copy; 2024 - All Rights Reserved - <a to="https://solidappmaker.com/" target='_blank'>www.solidappmaker.com</a></span>
                    </div>
                </div>
            </footer>

            {/* ****************Coming Soon Modal******************* */}
            <ComingSoonModal comingSoonModal={comingSoonModal} setComingSoonModal={setComingSoonModal} />

            {/* ****************Logout Modal******************* */}
            <Auth authModal={authModal} setAuthModal={setAuthModal} />
        </>
    )
}

export default Footer;
