import { Modal } from "antd";
import comingSoon from '../assets/images/comingsoon.png';
import closeIcon from '../assets/images/close.png';

const ComingSoonModal = ({ comingSoonModal, setComingSoonModal }) => {
    return (
        <>
            <Modal open={comingSoonModal} closable={false} footer='' centered className=''>
                <div className="p-3 p-md-4 p-lg-4">
                    <div className="text-end">
                        <img src={closeIcon} alt="close" width='30px' onClick={() => setComingSoonModal(false)} style={{ cursor: 'pointer' }} />
                    </div>
                    <div className="text-center  h-100">
                        <img src={comingSoon} alt="" width='100%' />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ComingSoonModal;
