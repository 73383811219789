import { useEffect, useState } from 'react';
import { Avatar, Badge, Button, Dropdown, Menu, Modal, Space } from 'antd';
import { fileUrl } from '../constant/url';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosArrowDown } from 'react-icons/io';
// import { FaShoppingCart } from "react-icons/fa";
import { getCartCount, getFetchApi } from '../redux/action';
import { authData, userDetail } from '../redux/reducers/AuthSlice';
import { locationData } from '../redux/reducers/CommonSlice';
import Auth from '../pages/Auth';
import Profile from '../pages/Profile';
import DeleteAccount from '../pages/DeleteAccount';
import ChangePassword from '../pages/ChangePassword';
import ComingSoonModal from './ComingSoonModal';
import Toast from '../utils/Toast';
import silverLogo from '../assets/images/silverlogo.png';
import userImg from '../assets/images/user.png';

const activeStyle = {
    borderBottom: "3px solid #fff",
}

const Header = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [profileModal, setProfileModal] = useState(false);
    const [deleteAccModal, setDeleteAccModal] = useState(false);
    const [passwordModal, setPasswordModal] = useState(false);
    const [authModal, setAuthModal] = useState(false);
    const [comingSoonModal, setComingSoonModal] = useState(false);
    const [logoutModal, setLogoutModal] = useState(false);
    const token = useSelector(state => state?.AuthSlice?.auth_data?.data?.token);
    const userData = useSelector(state => state?.AuthSlice?.user_detail);

    const dropDownItems = [
        {
            label: <dt className='so_small_font' onClick={() => setProfileModal(true)}>Edit Profile</dt>,
            key: 'editProfile',
        },
        {
            label: <dt className='so_small_font' onClick={() => setDeleteAccModal(true)}>Delete Account</dt>,
            key: 'deleteAccount',
        },
        {
            label: <dt className='so_small_font' onClick={() => setPasswordModal(true)}>Change Password</dt>,
            key: 'changePassword',
        },
        {
            label: <dt className='so_small_font' onClick={() => setLogoutModal(true)}>Logout</dt>,
            key: 'logoutKey',
        },
    ];

    const getUserDetails = () => {
        const apiCallBack = (res) => {
            if (res?.data?.data?.email == "" || res?.data?.data?.full_name == "" || res?.data?.data?.phone_no == "") {
                setProfileModal(true)
            }

            if (res?.data?.status) {
                dispatch(userDetail(res?.data?.data))
            } else {
                Toast('e', `${res?.message}`);
            }
        }
        let url = `user/get_details`
        dispatch(getFetchApi({ url, apiCallBack }))
    };

    const getLocationData = () => {
        const apiCallBack = (res) => {
            let data = res?.data?.data
            if (res?.data?.status) {
                dispatch(locationData(data))
            } else {
                dispatch(locationData())
            }
        }
        let data = {
            user_id: userData?._id
        }
        let url = `user/get_bars`;
        dispatch(getFetchApi({ url, data, apiCallBack }))
    };

    useEffect(() => {
        if (token) {
            getUserDetails();
            getLocationData();
            dispatch(getCartCount())
        }
    }, [token])

    const handleLogout = () => {
        navigate('/')
        localStorage.clear();
        Toast("s", "Logged out");
        dispatch(authData());
        setLogoutModal(false);
        window.location.reload();
    };

    return (
        <>
            <header className='header_div'>
                <div className='header_container'>
                    <div className="row g-0 d-flex align-items-center justify-content-between">
                        <div className="col-3 col-md-2 col-lg-2">
                            <NavLink to='/'><img src={silverLogo} alt="logo" className='logo_img_width' /></NavLink>
                        </div>
                        <div className="col-md-7 col-lg-8 d-none d-md-block d-lg-block">
                            <Menu theme="" mode="horizontal" className='header_anchor d-flex align-item-center justify-content-end border-0'>
                                <Menu.Item><NavLink to='/' style={({ isActive }) => isActive ? activeStyle : undefined}>Home</NavLink></Menu.Item>
                                <Menu.Item><NavLink to="/venues" style={({ isActive }) => isActive ? activeStyle : undefined}>Venues</NavLink></Menu.Item>
                                <Menu.Item><NavLink to="https://www.instagram.com/glenwoodsouthdistrict/reels/" target='_blank' >Feed</NavLink></Menu.Item>
                                {token ?
                                    <Menu.Item><NavLink to='/sky-cams' style={({ isActive }) => isActive ? activeStyle : undefined}>Sky Cams</NavLink></Menu.Item>
                                    :
                                    <Menu.Item><NavLink to='#' onClick={() => setAuthModal(true)}>Sky Cams</NavLink></Menu.Item>
                                }
                                <Menu.Item><NavLink to='/map' style={({ isActive }) => isActive ? activeStyle : undefined}>Map</NavLink></Menu.Item>
                                {/* <Menu.Item><NavLink to='/product-list' style={({ isActive }) => isActive ? activeStyle : undefined}>Shop</NavLink></Menu.Item> */}
                                <Menu.Item><NavLink to='#' onClick={() => setComingSoonModal(true)}>Shop</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/download' style={({ isActive }) => isActive ? activeStyle : undefined}>Download App</NavLink></Menu.Item>
                                <Menu.Item><NavLink to='/contact' style={({ isActive }) => isActive ? activeStyle : undefined}>Contact Us</NavLink></Menu.Item>
                            </Menu>
                        </div>
                        <div className="col-md-3 col-lg-2 d-none d-md-block d-lg-block text-end">
                            <div className="d-flex align-items-center justify-content-around">
                                {/* {token && <Badge count={cartCount ?? ''} offset={[-8, 2]}><FaShoppingCart size={30} color='red' style={{ cursor: "pointer", marginRight: "10px" }} onClick={() => navigate('/cart-list')} /></Badge>} */}
                                <div className="">
                                    {token ?
                                        <div className='drop_down_btn px-3 text-center rounded ms-auto'>
                                            <Dropdown menu={{ items: dropDownItems }} trigger={['click']} placement='bottomCenter'>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space role='button'>
                                                        <Avatar src={userData?.profile_pic ? `${fileUrl}${userData?.profile_pic}` : userImg} />
                                                        <span style={{ color: "#fff" }}>
                                                            {userData?.full_name}
                                                        </span>
                                                        <IoIosArrowDown style={{ color: "#fff" }} />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </div>
                                        :
                                        <button onClick={() => setAuthModal(true)} className='drop_down_btn'>Register</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {/* ****************Profile Modal******************* */}
            <Profile profileModal={profileModal} setProfileModal={setProfileModal} />

            {/* ***************Delete Modal****************** */}
            <DeleteAccount deleteAccModal={deleteAccModal} setDeleteAccModal={setDeleteAccModal} userData={userData} />

            {/* ***************Change Password Modal****************** */}
            <ChangePassword passwordModal={passwordModal} setPasswordModal={setPasswordModal} userData={userData} />

            {/* ****************Coming Soon Modal******************* */}
            <ComingSoonModal comingSoonModal={comingSoonModal} setComingSoonModal={setComingSoonModal} />

            {/* ****************Auth Modal******************* */}
            <Auth authModal={authModal} setAuthModal={setAuthModal} />

            {/* ****************Logout Modal******************* */}
            <Modal open={logoutModal} closable={false} footer='' centered>
                <div className="p-2 py-md-4 py-lg-5 text-center">
                    <h5 className='auth_title_font fs-4'>Are you sure?</h5>
                    <p className='medium_font'>Are you sure you want to logout?</p>

                    <div className='mt-4 mt-md-5 mt-lg-5 d-flex justify-content-center'>
                        <Button onClick={() => handleLogout()} className='common_btn shipping_btn' type="ghost" htmlType="submit">YES, LOGOUT</Button>
                        <Button onClick={() => setLogoutModal(false)} className='common_btn shipping_btn' type="ghost" style={{ background: "#ff0000" }}>CANCEL</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Header;