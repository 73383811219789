import { useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { useDispatch } from "react-redux";
import { getFetchApi } from "../redux/action";
import { authData } from "../redux/reducers/AuthSlice";
import { FaEye, FaEyeSlash, FaInstagram, FaApple, FaGooglePlusG } from 'react-icons/fa';
import { InstagramLogin } from '@amraneze/react-instagram-login';
import { GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";
import { socialAuth } from "../firebase";
import VerificationInput from 'react-verification-input';
import Toast from "../utils/Toast";
import StrongPassword from "../utils/StrongPassword";
import silverLogo from '../assets/images/silverlogo.png';
import closeIcon from '../assets/images/close.png';

const Auth = ({ authModal, setAuthModal }) => {
    const dispatch = useDispatch();
    const [formFields] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [screen, setScreen] = useState('1');
    const [otpVal, setOtpVal] = useState('');
    const [email, setEmail] = useState('');


    // Register function
    const handleRegister = async (formVal) => {
        if (formVal?.password !== formVal?.c_password) {
            Toast('e', 'Both password does not match');
            return
        }
        try {
            await StrongPassword(formVal?.password);
            setLoading(true)
            const apiCallBack = (res) => {
                setLoading(false)
                if (res?.data?.status) {
                    setScreen('1')
                    Toast('s', `${res?.data?.message}`);
                } else {
                    Toast('e', `${res?.data?.message}`);
                }
            }
            let data = {
                full_name: formVal?.full_name,
                email: formVal?.email?.toLowerCase(),
                password: formVal?.password,
                os: 'web'
            }
            let url = `user/sign_up`
            dispatch(getFetchApi({ url, data, apiCallBack }))
        } catch (error) {
            Toast('e', error);
        }
    }

    // Login function
    const handleLogin = (formVal) => {
        setLoading(true)
        const apiCallBack = (res) => {
            setLoading(false)
            if (res?.data?.status) {
                setAuthModal(false)
                dispatch(authData(res?.data));
                Toast('s', `${res?.data?.message}`);
                formFields.resetFields();
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = {
            email: formVal?.email?.toLowerCase(),
            password: formVal?.password,
            os: 'web'
        }
        let url = `user/sign_in`
        dispatch(getFetchApi({ url, data, apiCallBack }))
    };

    // Forgot function
    const handleForgot = (formVal) => {
        setEmail(formVal?.email)
        setLoading(true)
        const apiCallBack = (res) => {
            setLoading(false)
            if (res?.data?.status) {
                Toast('s', `${res?.data?.message}`);
                setScreen('4');
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = { email: formVal?.email?.toLowerCase() }
        let url = `user/forget_password`
        dispatch(getFetchApi({ url, data, apiCallBack }))
    }

    // Email Verification function
    const handleEmailVerify = () => {
        setLoading(true)
        const apiCallBack = (res) => {
            setLoading(false)
            if (res?.data?.status) {
                Toast('s', `${res?.data?.message}`);
                setScreen('5');
            } else {
                Toast('e', `${res?.data?.message}`);
            }
        }
        let data = { email: email?.toLowerCase(), otp: otpVal }
        let url = `user/verify_otp`
        dispatch(getFetchApi({ url, data, apiCallBack }))
    }

    // Change Password function
    const handleChangePassword = async (formVal) => {
        if (formVal?.new_password != formVal?.c_password) {
            Toast('e', `Both password must be same`)
            return
        }
        try {
            await StrongPassword(formVal?.new_password)
            setLoading(true)
            const apiCallBack = (res) => {
                setLoading(false)
                if (res?.data?.status) {
                    Toast('s', `${res?.data?.message}`)
                    setScreen('1')
                    setOtpVal('')
                } else {
                    Toast('e', `${res?.data?.message}`)
                }
            }
            let data = { email: email?.toLowerCase(), otp: otpVal, password: formVal?.new_password }
            let url = `user/reset_password`
            dispatch(getFetchApi({ url, data, apiCallBack }))
        } catch (error) {
            Toast('e', error)
        }
    }

    // Social Login
    const handleInstaLogin = async (response) => {
        let resp = await response;
        fetchUserProfile(resp);
        setLoading(true);
        try {
            const apiCallBack = (res) => {
                setLoading(false);
                if (res?.data?.status) {
                    setAuthModal(false);
                    dispatch(authData(res?.data));
                    Toast('s', `${res?.data?.message}`);
                } else {
                    Toast('e', `${res?.data?.message}`);
                }
            }
            let data = {
                login_source: 'instagram',
                social_token: resp,
                full_name: '',
                email: '',
                os: 'web'
            }
            let url = `user/social_login`;
            dispatch(getFetchApi({ url, data, apiCallBack }));
        } catch (error) {
            Toast('e', `${error?.message}`);
        }
    };

    const handleAppleLogin = () => {
        setLoading(true)
        const provider = new OAuthProvider('apple.com');
        provider.addScope('email');
        provider.addScope('name');
        signInWithPopup(socialAuth, provider)
            .then((result) => {
                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;

                // The signed-in user info.
                const user = result.user;
                const apiCallBack = (res) => {
                    setLoading(false)
                    if (res?.data?.status) {
                        setAuthModal(false)
                        dispatch(authData(res?.data));
                        Toast('s', `${res?.data?.message}`);
                    } else {
                        Toast('e', `${res?.message}`);
                    }
                }
                let data = {
                    login_source: 'apple',
                    social_token: accessToken,
                    full_name: user?.displayName,
                    email: user?.email,
                    os: 'web'
                }
                let url = `user/social_login`
                dispatch(getFetchApi({ url, data, apiCallBack }))
            }).catch((error) => {
                console.log(error, '>>>>>>>>error')
                setLoading(false)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The credential that was used.
                const credential = OAuthProvider.credentialFromError(error);
                // ...
                console.log("Apple Login Failure ", errorCode, errorMessage, email, credential)
            });
    };

    const handleGoogleLogin = () => {
        setLoading(true)
        const provider = new GoogleAuthProvider();
        signInWithPopup(socialAuth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                const apiCallBack = (res) => {
                    setLoading(false)
                    if (res?.data?.status) {
                        setAuthModal(false)
                        dispatch(authData(res?.data));
                        Toast('s', `${res?.data?.message}`);
                    } else {
                        Toast('e', `${res?.data?.message}`);
                    }
                }
                let data = {
                    login_source: 'google',
                    social_token: token,
                    full_name: user?.displayName ? user?.displayName : user?.providerData?.[0]?.displayName,
                    email: user?.email ? user?.email : user?.providerData?.[0]?.email,
                    os: 'web'
                }
                let url = `user/social_login`
                dispatch(getFetchApi({ url, data, apiCallBack }))
            }).catch((error) => {
                setLoading(false)
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(error, '>>>>>>>>>>error')
            });
    }

    // User Details for insta
    const fetchUserProfile = async (accessToken) => {
        try {
            const proxyUrl = 'http://localhost:3000/proxy/instagram';
            const apiUrl = `https://graph.instagram.com/me?fields=id,username,picture,phone`;
            const response = await fetch(`${proxyUrl}?url=${encodeURIComponent(apiUrl)}&accessToken=${accessToken}`);
            const responseBody = await response.json();

            console.log(responseBody, '>>>>>>>>>>>>responseBodyresponseBody')

            if (!response.ok) {
                throw new Error(responseBody.error?.message || 'Unknown error occurred');
            }

            console.log('API Response:', responseBody);
            // Extract user ID, username, profile picture, and phone number from responseBody
        } catch (error) {
            console.error('Error fetching user profile:', error.message);
        }
    };

    return (
        <>
            <Modal open={authModal} closable={false} footer='' className='common_modal_width' centered onCancel={() => { setAuthModal(false); setScreen('1'); formFields.resetFields() }}>
                <div className="row gx-0">
                    <div className="col-md-6 col-lg-6 d-none d-md-block d-lg-block" style={{ background: "#000" }}>
                        <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <img src={silverLogo} alt="filter" width='25%' />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6 px-3 px-lg-5 py-3" style={{ position: 'relative' }}>
                        <div className="closeIcon">
                            <img src={closeIcon} alt="close" width='30px' onClick={() => { setAuthModal(false); setScreen('1') }} style={{ cursor: 'pointer' }} />
                        </div>
                        <div className="text-center auth_section">
                            {screen == "1" &&
                                <div className="login_section">
                                    <h1 className="auth_title_font">SIGN IN</h1>
                                    <p className="auth_sub_title mb-4 mb-md-5 mb-lg-5">Sign In to your registered email & password</p>
                                    <Form form={formFields} onFinish={handleLogin} autoComplete="off">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email address!',
                                                },
                                            ]}
                                        >
                                            <Input type='email' className='common_input profile_input' placeholder='Email Address' />
                                        </Form.Item>
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password type='password' placeholder='Enter Password' className='common_input profile_input'
                                                iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                            />
                                        </Form.Item>
                                        <div className='text-end mb-5 d-flex justify-content-between align-items-center'>
                                            <div className='d-flex align-items-center'>
                                                {/* <input type='checkbox' className='check_box_input' /> */}
                                                {/* <dt className='small_font text-dark fw-normal'>Remember me</dt> */}
                                            </div>
                                            <b onClick={() => setScreen("3")} className='small_font' style={{ color: "#000", cursor: "pointer" }}>Forgot password?</b>
                                        </div>
                                        <Form.Item>
                                            <Button loading={loading} className='common_btn common_btn_width' type="ghost" htmlType="submit">SIGN IN</Button>
                                        </Form.Item>
                                    </Form>
                                    <div className="row d-flex justify-content-center align-items-center my-4">
                                        <div className="col-2">
                                            <hr style={{ backgroundColor: "#000", height: "2px" }} />
                                        </div>
                                        <div className="col-2">
                                            <span className='auth_sub_title'>OR</span>
                                        </div>
                                        <div className="col-2">
                                            <hr style={{ backgroundColor: "#000", height: "2px" }} />
                                        </div>
                                    </div>
                                    <div className="social_buttons text-center">
                                        {/* <button><FaInstagram size={25} /></button> */}
                                        <InstagramLogin
                                            clientId={979408943742036}
                                            appSecret={'947870838d49b9c68674a94ddb21e39a'}
                                            onSuccess={handleInstaLogin}
                                            onFailure={handleInstaLogin}
                                            scopes={["user_profile", "user_media"]}
                                            cssClass="insta_"
                                            redirectUri={"https://www.glenwoodsouth.com/"}
                                        >
                                            <FaInstagram size={30} />
                                        </InstagramLogin>
                                        <button onClick={handleAppleLogin}><FaApple size={30} /></button>
                                        <button onClick={handleGoogleLogin}><FaGooglePlusG size={30} /></button>
                                        <h6 className='small_font mt-5'>Don't have an account? <span onClick={() => setScreen("2")}><b style={{ color: "#000", cursor: "pointer" }}>Sign Up</b></span></h6>
                                    </div>
                                </div>
                            }

                            {screen == "2" &&
                                <div className="register_section">
                                    <h1 className="auth_title_font">SIGN UP</h1>
                                    <p className="auth_sub_title mb-4 mb-md-5 mb-lg-5">Please fill the details to Sign Up</p>
                                    <Form onFinish={handleRegister} autoComplete="off" >
                                        <Form.Item
                                            name="full_name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your full name!',
                                                },
                                            ]}
                                        >
                                            <Input className='common_input profile_input' placeholder='Full Name' />
                                        </Form.Item>
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your email address!',
                                                },
                                            ]}
                                        >
                                            <Input type='email' className='common_input profile_input' placeholder='Email Address' />
                                        </Form.Item>
                                        {/* <Form.Item
                                            name="phone_no"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your phone number!',
                                                },
                                            ]}
                                        >
                                            <Input maxLength={10} className='common_input profile_input' placeholder='Phone Number' />
                                        </Form.Item> */}
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password type='password' placeholder='Enter Password' className='common_input profile_input'
                                                iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="c_password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input confirm password!',
                                                },
                                            ]}
                                        >
                                            <Input.Password type='password' placeholder='Confirm Password' className='common_input profile_input'
                                                iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button loading={loading} className='common_btn common_btn_width mt-2' type="ghost" htmlType="submit">REGISTER</Button>
                                        </Form.Item>
                                        <h6 className='small_font py-2 py-lg-4'>Already have an account? <span onClick={() => setScreen("1")}><b style={{ color: "#000", cursor: "pointer" }}>Sign In</b></span></h6>
                                    </Form>
                                </div>
                            }

                            {screen == "3" &&
                                <div className='forgot_section d-flex align-items-center'>
                                    <div className="w-100">
                                        <h1 className="auth_title_font">FORGOT PASSWORD</h1>
                                        <p className="auth_sub_title mb-4 mb-md-5 mb-lg-5">Enter the email associated with your account.</p>
                                        <Form onFinish={handleForgot} autoComplete="off" >
                                            <Form.Item
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your email address!',
                                                    },
                                                ]}
                                            >
                                                <Input type='email' className='common_input profile_input' placeholder='Email Address' />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button loading={loading} className='common_btn common_btn_width mt-4' type="ghost" htmlType="submit">CONTINUE</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            }

                            {screen == "4" &&
                                <div className='email_verification d-flex align-items-center'>
                                    <div className="w-100">
                                        <h1 className="auth_title_font">EMAIL VERIFICATION</h1>
                                        <p className="auth_sub_title mb-4 mb-md-5 mb-lg-5">A 4-digit verification code was sent to your <br /> email {email}</p>
                                        <div className="row">
                                            <div className="col-sm-8 col-md-12 col-lg-12 col-xxl-10 mx-auto">
                                                <div className="text-start">
                                                    <h6 className='medium_font fw-bold'>Enter OTP</h6>
                                                    <VerificationInput
                                                        placeholder="*"
                                                        length={4}
                                                        value={otpVal}
                                                        onChange={(val) => setOtpVal(val)}
                                                        autoFocus
                                                    />
                                                    <div className="d-flex justify-content-between mt-5">
                                                        <b className="small_font">Didn't receive the code?</b>
                                                        <b className="small_font" onClick={() => handleForgot({ email: email })} style={{ cursor: "pointer", color: "#000" }}>Resend</b>
                                                    </div>
                                                </div>
                                                <Button loading={loading} onClick={handleEmailVerify} className='common_btn w-100 mt-3 mt-lg-4' type="ghost" htmlType="submit">CONTINUE</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {screen == "5" &&
                                <div className='change_password d-flex align-items-center'>
                                    <div className="w-100">
                                        <h1 className="auth_title_font">CHANGE PASSWORD</h1>
                                        <p className="auth_sub_title mb-4 mb-md-5 mb-lg-5">Set a New password</p>
                                        <Form onFinish={handleChangePassword} autoComplete="off" >
                                            <Form.Item
                                                name="new_password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your new password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password type='password' placeholder='New Password' className='common_input profile_input'
                                                    iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                                />
                                            </Form.Item>
                                            <Form.Item
                                                name="c_password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input confirm password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password type='password' placeholder='Confirm Password' className='common_input profile_input'
                                                    iconRender={(visible) => (visible ? <FaEye color='#707070' size={18} /> : <FaEyeSlash color='#707070' size={18} />)}
                                                />
                                            </Form.Item>

                                            <Form.Item>
                                                <Button loading={loading} className='common_btn common_btn_width mt-4' type="ghost" htmlType="submit">CONTINUE</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Auth;
