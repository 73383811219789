import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    common_data: {},
    tab_data: {},
    location_data: {},
    address_data: {},
}

export const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        commonData: (state, action) => {
            state.common_data = action.payload
        },
        tabDataDetail: (state, action) => {
            state.tab_data = action.payload
        },
        locationData: (state, action) => {
            state.location_data = action.payload
        },
        addressData: (state, action) => {
            state.address_data = action.payload
        },
    }
});

export const { locationData, tabDataDetail, commonData, addressData } = commonSlice.actions
export default commonSlice.reducer